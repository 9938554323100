.login_logo {
  width: 75%;
  max-width: 300px;
  display: block;
  position: relative;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.login ion-col > div {
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-family: inherit;
  color: #737373;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
}
.login ion-row {
  justify-content: center;
}

.login ion-label {
  color: #737373;
}

.login_form {
  max-width: 600px;
  height: 90vh;
}
