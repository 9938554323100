.modal_section {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.modal_section-header {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.modal_section-header__buttons {
  align-self: flex-end;
}

.modal_section-header__info {
  font-size: 22px;
}
.modal-content {
  overflow: scroll;
}

.modal-content p {
  margin-bottom: 1em;
}

.modal-content p:last-child {
  margin-bottom: 0;
}
.modal__section {
  border: none;
  box-shadow: none;
}
.modal__section .modal-wrapper {
  background: #fff;
  border-radius: 4px;
}
ion-col > div {
  padding: 10px;
  margin: 10px;
  background-color: white;
  text-align: left;
  font-family: inherit;
  color: #737373;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}
.onderschrift {
  font-size: 10px;
}
.modalAvatar {
  height: 450px;
}

.modalAvatar_medium {
  width: 100%;
}

.modalAvatar_small {
  height: 225px;
}
.swiper-container {
  overflow-y: auto;
}

.swiper-wrapper {
  width: 99%;
  height: 99%;
  margin: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  border: none;
  @media only screen and (min-width: 1140px) {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  right: 20px;
  border: 1px solid #dee2e7;
}

.Modalicon {
  color: #dee2e7;
}
