/* Hide the input component,
 * see https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
 */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
