.table-wrapper {
  overflow-x: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

table {
  width: 100%;
}

th,
td {
  padding: 15px;
  text-align: left;
}

thead th {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

tr:hover {
  background-color: var(--ion-color-light);
}

.table__row_border {
  border-bottom: 1px solid var(--ion-color-medium);
}

.table__row_indent {
  padding-left: 60px;
}

.table_border tr {
  border-bottom: 1px solid var(--ion-color-medium);
}
