.avatars {
  /* 600 - left margin 10+30 - right margin 30 */
  width: 510px;
  height: 75px;
  position: relative;
}

.avatars__img {
  position: absolute;
  left: 0;
  width: 30px;
}

.ResponsiveContainer {
  margin: 0px;
  padding: 0px;
  height: 300px;
}

.graphTitle {
  font-size: 14px;
  text-align: center;
}
.graphSubtitle {
  font-size: 10px;
  text-align: center;
}
text.recharts-text.recharts-label {
  font-family: 'Helvetica Neue';
  color: #737373;
  font-size: 10px;
  font-weight: normal;
  line-height: 1.5;
}
