.login ion-grid {
  width: 100%;
  box-sizing: border-box;
  height: 90vh;
}
.login-col_left {
  display: flex;
  background-color: #70707025;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
}
.login-col {
  text-align: center;
}
.intro_bg {
  width: calc(100% - 86px);
  max-width: 600px;
  position: absolute;
  opacity: 0.75;
  margin-top: 20px;
}
.intro_image {
  position: absolute;
  width: calc(100% - 86px);
  max-width: 600px;
}
