.avatar {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.mobility__title {
  display: block;
  text-align: center;
  font-size: 16px;
  color: var(--ion-color-primary);
  margin-top: 10px;
}
.speed {
  font-weight: bold;
}
.SpeedLabel {
  background-color: green;
  border-radius: 10px;
  padding: 2px 10px;
  color: #ffffff;
  margin-left: 10px;
}
.warning {
  background-color: orange !important;
}
.danger {
  background-color: red;
  color: #ffffff;
}

.cardTitle {
  font-size: 17px;
}
.cardSubtitle {
  font-size: 10px;
}

.section__modal .modal-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 1000px;
  height: 650px;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

ion-modal {
  --ion-background-color: #ffffff;
}
.section-content p {
  margin-bottom: 1em;
}

.section-content p:last-child {
  margin-bottom: 0;
}

.section-header__buttons {
  align-self: flex-end;
}

.section-header__info {
  font-size: 22px;
}
.section {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
}
.sectionerror {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  border: 1px solid var(--ion-color-danger);
}

.section-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}
