.section {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
}

.section-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.section-header_graph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}

.section-header__buttons {
  align-self: flex-end;
}

.section-header__info {
  font-size: 22px;
}

.section-content p {
  margin-bottom: 1em;
}

.section-content p:last-child {
  margin-bottom: 0;
}
.graphsection-content {
  margin-bottom: 0;
  padding-top: 10px;
}
.cardTitle {
  font-size: 17px;
}
.cardSubtitle {
  font-size: 10px;
}

.section__modal .modal-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 1000px;
  height: 650px;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

ion-modal {
  --ion-background-color: #ffffff;
}
