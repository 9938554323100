.header__logo {
  margin-top: 2px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.Docsheader__logo {
  margin-top: 2px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.Title {
  max-width: 240px;
  border: 1px solid black;
  box-sizing: border-box;
  margin-right: 0px !important;
}

.nav_buttons {
  position: relative;
  margin-left: 300px;
  margin-top: 2px;
}

ion-title > div {
  margin-bottom: 20px;
  text-align: center;
  font-family: inherit;
  color: #737373;
  font-size: 10px;
  font-weight: normal;
  line-height: 1.5;
}
