.toolbar {
  padding-right: 48px;
}

.header__logo {
  margin-top: 2px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}
